<template>
  <div class="modal is-active px-2">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head has-text-centered">
        <p class="modal-card-title has-monserrat-font">Completion Certificate</p>
      </header>
      <section class="modal-card-body has-text-centered">
        <div class="is-size-6 has-text-weight-semibold has-text-centered mb-1 has-monserrat-font">
          Cert Number
        </div>
        <div v-if="completionCert" class="is-size-7 has-text-centered mb-3 has-monserrat-font">
          {{ completionCert.certNumber }}
        </div>
        <i v-if="!completionCert" class="spinning fas fa-circle-notch pointer has-text-grey-light"></i>
        <div v-if="completionCert && completionCert.briefing" class="notification is-size-7 has-text-centered mb-3 p-2 multiline">
          {{ completionCert ? completionCert.briefing : 'loading...' }}
        </div>
        <div v-if="qrcodeValue" class="container has-text-centered mt-3 mb-2">
          <qrcode-vue :value="qrcodeValue" :size="150" level="H" />
        </div>
        <div class="container has-text-centered">
          <span v-if="completionCert.validated" class="tag is-success is-rounded">Validated</span>
        </div>
        <div class="has-text-weight-semibold is-size-6 mt-1">
          Certificate details
        </div>
        <div class="container is-size-7">
          Title: {{ completionCert ? completionCert.adventureName : 'loading...' }}
        </div>
        <div class="container is-size-7">
          Started: {{ completionCert ? completionCert.startedAt.toDate().toLocaleString() : 'loading...' }}
        </div>
        <div class="container is-size-7">
          Completed: {{ completionCert ? completionCert.completedAt.toDate().toLocaleString() : 'loading...' }}
        </div>
        <div class="container is-size-7">
          Points: {{ completionCert ? completionCert.totalPoints : 'loading...' }}
        </div>
        <div class="container is-size-7">
          Duration: {{ completionCert ? `${((completionCert.completedAt.toDate().getTime() - completionCert.startedAt.toDate().getTime())/1000/60).toFixed(2)} minutes` : 'loading...' }}
        </div>
        <div class="container is-size-7">
          Players ({{ completionCert ? completionCert.teamMembers.length : '?' }}): {{ completionCert ? completionCert.teamMembers.map(x => x.name).join(', ') : 'loading...' }}
        </div>
      </section>
      <footer class="modal-card-foot">
      </footer>
    </div>
  </div>
</template>

<script>
import firebaseApp from '@/firebase/init'
import QrcodeVue from 'qrcode.vue'

export default {
  name: 'CompletionCert',
  components: {
    QrcodeVue
  },
  data () {
    return {
      certNumber: this.$route.query.number,
      completionCert: null
    }
  },
  mounted () {
    if (this.certNumber) {
      firebaseApp.firestore().collection('completionCerts')
      .doc(this.certNumber)
      .get()
      .then(doc => {
        this.completionCert = doc.data()
      })
    }
  },
  computed: {
    qrcodeValue(){
      if (this.completionCert) {
        return `${window.location.origin}/cert?number=${this.certNumber}`
      }
      return 'loading...'
    }
  }
}
</script>

<style>

</style>